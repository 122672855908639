import React from 'react';
import {LineChart} from "components/ui";
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData5 from "data/data-5-removal-age-under-1.js"
import StatisticsEntry from 'templates/statistics-detail-page-layout';

const isBrowser = typeof window !== "undefined"

const StatisticsPage5=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
        const pageData = interactiveStatisticsCharts.filter(data=>data.id === 6)
        const interactiveStatisticsPageData = pageData[0];
    return (
        <StatisticsEntry
        location={location}
        title={interactiveStatisticsPageData.title}
        description={interactiveStatisticsPageData.metaDescription}
        author={interactiveStatisticsPageData.author}
        year={interactiveStatisticsPageData.year}
        note={<span>Estimates based on <strong className="text-underline">children under age 1 who entered out of home care</strong> during the Fiscal Year</span>}
        fileName={interactiveStatisticsPageData.fileName}
        fileSize={interactiveStatisticsPageData.fileSize}>
            <LineChart 
            title="Percentage"
            chartLabels={[...StatisticsData5.map(data=>data.xAxis)]}
            chartData={[...StatisticsData5.map(data=>data.yAxis)]}
            xAxisLabel="Year"
            yAxisLabel="Percentage"
            yAxisMax={60}
            yAxisMin={20}
            backgroundColor="#383E56"
            borderColor="#383E56"
            isPercent={true}
            />
            <p>
                The percentage of children under age 1 who entered out-of-home care with parental alcohol or drug abuse as a condition associated with removal increased from 27.8% in 2000 to 53.5% in 2021.
            </p>
        </StatisticsEntry>
    )
}
export default StatisticsPage5;