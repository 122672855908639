const StatisticsData5=[
    {
        id: 1,
        xAxis: "2000",
        yAxis: "27.8"
    },
    {
        id: 2,
        xAxis: "2001",
        yAxis: "28.3"
    },
    {
        id: 3,
        xAxis: "2002",
        yAxis: "30.7"
    },
    {
        id: 4,
        xAxis: "2003",
        yAxis: "32.7"
    },
    {
        id: 5,
        xAxis: "2004",
        yAxis: "34.4"
    },
    {
        id: 6,
        xAxis: "2005",
        yAxis: "36.1"
    },
    {
        id: 7,
        xAxis: "2006",
        yAxis: "36.7"
    },
    {
        id: 8,
        xAxis: "2007",
        yAxis: "37.6"
    },
    {
        id: 9,
        xAxis: "2008",
        yAxis: "37.3"
    },
    {
        id: 10,
        xAxis: "2009",
        yAxis: "36.8"
    },
    {
        id: 11,
        xAxis: "2010",
        yAxis: "40.1"
    },
    {
        id: 12,
        xAxis: "2011",
        yAxis: "40.6"
    },
    {
        id: 13,
        xAxis: "2012",
        yAxis: "42.7"
    },
    {
        id: 14,
        xAxis: "2013",
        yAxis: "42.4"
    },
    {
        id: 15,
        xAxis: "2014",
        yAxis: "43.5"
    },
    {
        id: 16,
        xAxis: "2015",
        yAxis: "47.0"
    },
    {
        id: 17,
        xAxis: "2016",
        yAxis: "48.9"
    },
    {
        id: 18,
        xAxis: "2017",
        yAxis: "51.2"
    },
    {
        id: 19,
        xAxis: "2018",
        yAxis: "52.0"
    },
    {
        id: 20,
        xAxis: "2019",
        yAxis: "50.9"
    },
    {
        id: 21,
        xAxis: "2020",
        yAxis: "51.4"
    },
    {
        id: 22,
        xAxis: "2021",
        yAxis: "53.5"
    }

]
export default StatisticsData5;